<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-4" v-if="isSearch">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Type here to search..."
            v-model="searchValue"
            @keyup.enter="search"
          />
          <span class="input-group-text" id="basic-addon2" @click="search"
            ><i class="fa fa-search"></i
          ></span>
          <span class="input-group-text" id="basic-addon2" @click="clearSearch"
            ><i class="icon-close"></i
          ></span>
        </div>
      </div>
    </div>
    <table class="table" style="border: 1px solid #ddd">
      <thead>
        <tr>
          <th v-if="isChecked">#</th>
          <th v-if="batchStudentCheck">#</th>
          <th v-if="isGroupCheck">#</th>
          <th v-for="column in columns">
            <div
              class="d-flex justify-content-between align-items-center"
              style="position: relative"
            >
              <span>{{ column.text }}</span>

              <div
                class="d-flex flex-column align-items-center"
                style="position: relative; height: 18px"
                @click="columnSorting(column)"
              >
                <!-- Sort Up Icon -->
                <!-- <i
                  class="fa fa-sort-up fa-lg"
                  :style="[
                    sortUpStyle,
                    isSortedAsc(column) ? activeStyle : inactiveStyle,
                  ]"
                  style="
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    line-height: 1;
                    margin: 0;
                    padding: 0;
                  "
                >
                </i> -->

                <!-- Sort Down Icon -->
                <!-- <i
                  class="fa fa-sort-down fa-lg"
                  :style="[
                    sortDownStyle,
                    isSortedDesc(column) ? activeStyle : inactiveStyle,
                  ]"
                  style="
                    cursor: pointer;
                    position: absolute;
                    bottom: 0;
                    line-height: 1;
                    margin: 0;
                    padding: 0;
                  "
                >
                </i> -->
              </div>
            </div>
          </th>
          <th v-if="isAction"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in items.data" :key="i">
          <td v-if="isChecked">
            {{ resetCheckbox() }}
            <input
              type="checkbox"
              class="form-check-input checkBoxesBatch"
              @change="checkboxChanged(item)"
              checked
            />
          </td>
          <td v-if="batchStudentCheck">
            <input
              type="checkbox"
              class="form-check-input checkBoxesBatch"
              @change="checkboxChanged(item)"
            />
          </td>
          <td v-if="isGroupCheck">
            <input
              type="checkbox"
              class="form-check-input groupcheckboxes"
              :value="item"
              @change="toggleSelection(item)"
            />
          </td>
          <td v-for="column in columns">
            <p class="text-ellipsis">
              <template v-if="column.secondValue && column.thirdValue">
                {{
                  getNestedValue(
                    item,
                    column.value,
                    column.secondValue,
                    column.thirdValue
                  )
                }}
              </template>
              <template v-else-if="column.secondValue">
                {{
                  item[column.value] && item[column.value][column.secondValue]
                    ? item[column.value][column.secondValue]
                    : ""
                }}
              </template>
              <template v-else>
                {{ item[column.value] }}
              </template>
            </p>
          </td>
          <td v-if="isAction">
            <div class="dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <vue-feather type="more-vertical"></vue-feather>
              </button>
              <ul class="dropdown-menu dropdown-menu-lg-end">
                <li v-if="isTrashed">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="checkboxChanged(item)"
                    >Delete</a
                  >
                </li>
                <li v-if="isChangePassword">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="changePassword(item)"
                    >Change Password</a
                  >
                </li>
                <li v-if="module == 'courses' && (item.editable || isEdit)">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    :data-bs-target="'#addLevelModal' + index"
                    @click="selectItem(item)"
                    >Add Level</a
                  >
                </li>
                <li
                  v-if="module == 'courseLevels' && (item.editable || isEdit)"
                >
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    :data-bs-target="'#addMaterialModal' + index"
                    @click="selectItem(item)"
                    >Add Material</a
                  >
                </li>
                <li v-if="isView">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="selectItem(item, true)"
                    >View Detail</a
                  >
                </li>
                <li v-if="isParticipent">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="selectItem(item, true)"
                    >View Participants</a
                  >
                </li>
                <li v-if="materialEdit">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="editMaterial(item)"
                    >Edit Material</a
                  >
                </li>
                <li v-if="item.editable || isEdit">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="openItem(item)"
                    >Edit</a
                  >
                </li>
                <li v-if="status && item.status == 'Active'">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="handleStatus(item.status, item.id)"
                    >Remove</a
                  >
                </li>

                <li v-if="isBlock && item?.status == 'Active'">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    :data-bs-target="'#blockModal' + index"
                    @click="selectItem(item)"
                    >Block</a
                  >
                </li>
                <li v-if="isBlock && item?.status == 'Blocked'">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    :data-bs-target="'#approveModal' + index"
                    @click="selectItem(item)"
                    >Unblock</a
                  >
                </li>

                <li v-if="isBlock && item?.user?.status == 'Active'">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    :data-bs-target="'#blockModal' + index"
                    @click="selectItem(item)"
                    >Block</a
                  >
                </li>
                <li v-if="isBlock && item?.user?.status == 'Blocked'">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    :data-bs-target="'#approveModal' + index"
                    @click="selectItem(item)"
                    >Unblock</a
                  >
                </li>
                <li v-if="isDelete">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    :data-bs-target="'#deleteModal' + index"
                    @click="selectItem(item)"
                    >Delete</a
                  >
                </li>
                <li v-if="supportEmail">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    @click="openEmail(item)"
                    >Send Response Email</a
                  >
                </li>
                <li v-if="endBatch && item.isEnd == 0">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    :data-bs-target="'#batchEnd' + index"
                    @click="selectItem(item)"
                    >End Batch</a
                  >
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row mt-3">
      <div class="col-6 d-flex align-items-center">
        <span v-if="items.to && items.from && items.total">
          Showing {{ items.from }} to {{ items.to }} of {{ items.total }}
          {{ entryLabel }}
        </span>
      </div>
      <div class="col-6 d-flex justify-content-end align-items-center">
        <nav aria-label="Page navigation example">
          <ul class="pagination pagination-sm justify-content-end mb-0">
            <li class="page-item" v-for="page in items.links" :key="page.label">
              <a
                href="javascript:void(0);"
                class="page-link"
                :class="{ active: page.active }"
                v-if="page.url != null"
                @click="movePages(page.url)"
              >
                <span v-html="page.label"></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <button
      v-if="isGroupCheck"
      class="btn btn-sm btn-primary primary-bg-color mt-3"
      @click="createGroup"
    >
      Create Group
    </button>

    <!-- Delete Modal -->
    <deleteModal
      :index="index"
      :selectedItem="selectedItem"
      :module="module"
      @deleted="handleDelete"
    ></deleteModal>

    <!-- Block Modal -->
    <div
      class="modal fade"
      :id="'blockModal' + index"
      tabindex="-1"
      aria-labelledby="blockModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to block {{ selectedItem?.name }}?</p>
            <div class="form-group">
              <label>Write a reason to block. (optional)</label>
              <input
                type="text"
                class="custom-form-control"
                v-model="blockReason"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary"
              data-bs-dismiss="modal"
              @click="blockUser"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Approve Modal -->
    <div
      class="modal fade"
      :id="'approveModal' + index"
      tabindex="-1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to Unblock {{ selectedItem?.name }}?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary"
              data-bs-dismiss="modal"
              @click="approveUser"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Level Modal -->
    <addLevelModal
      :index="index"
      :selectedItem="selectedItem"
      :module="module"
    ></addLevelModal>

    <!-- Add Material Modal -->
    <addMaterialModal
      :index="index"
      :selectedItem="selectedItem"
      :module="module"
    ></addMaterialModal>

    <div
      ref="addLevel"
      class="modal fade"
      :id="'batchEnd' + index"
      tabindex="-1"
      aria-labelledby="batchEndModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                {{ selectedItem?.title }}
              </h5>
            </div>
            <p>Are you sure you want to end this batch?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-sm primary-bg-color text-white"
              data-bs-dismiss="modal"
              @click="confirmEndBatch"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";
import deleteModal from "./modals/delete";
import addLevelModal from "./modals/addLevel";
import addMaterialModal from "./modals/addMaterial";

export default {
  components: {
    deleteModal,
    addLevelModal,
    addMaterialModal,
  },
  computed: {
    entryLabel() {
      return this.items.total === 1 ? "entry" : "entries";
    },
  },
  props: {
    columns: [],
    items: [],
    module: null,
    isChangePassword: false,
    type: null,
    index: 1,
    isEdit: false,
    isDelete: false,
    isView: false,
    isChecked: false,
    isTrashed: false,
    isGroupCheck: false,
    isAddLevel: false,
    isParticipent: false,
    status: false,
    batchStudentCheck: false,
    materialEdit: false,
    endBatch: false,
    isBlock: false,
    isApprove: false,
    isSendNotification: false,
    supportEmail: false,
    isSearch: {
      type: Boolean,
      default: true,
    },
    isAction: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchValue: "",
      loading: false,
      selectedItem: null,
      selectedStudents: [],
      blockReason: null,
      currentSortColumn: null,
      currentSortDirection: "",
      activeStyle: {
        opacity: "1",
      },
      inactiveStyle: {
        opacity: "0.2",
      },
    };
  },
  created() {
    if (this.items !== undefined) {
      if (this.items.length > this.pageSize) {
        this.totalPages = this.items.length / this.pageSize;
      }
    }
  },
  methods: {
    columnSorting(column) {
      if (this.currentSortColumn === column) {
        if (this.currentSortDirection === "") {
          this.currentSortDirection = "asc";
        } else if (this.currentSortDirection === "asc") {
          this.currentSortDirection = "desc";
        } else if (this.currentSortDirection === "desc") {
          this.currentSortDirection = "";
          this.currentSortColumn = null;
        }
      } else {
        this.currentSortColumn = column;
        this.currentSortDirection = "asc";
      }
    },
    isSortedAsc(column) {
      return (
        this.currentSortColumn === column && this.currentSortDirection === "asc"
      );
    },
    isSortedDesc(column) {
      return (
        this.currentSortColumn === column &&
        this.currentSortDirection === "desc"
      );
    },
    getNestedValue(obj, firstKey, secondKey, thirdKey) {
      return obj?.[firstKey]?.[secondKey]?.[thirdKey] ?? "";
    },
    search() {
      this.$emit("search", this.searchValue);
    },
    clearSearch() {
      this.searchValue = "";
      this.$emit("clear", this.searchValue);
    },
    movePages(url) {
      if (process.env.NODE_ENV && process.env.NODE_ENV == "production") {
        url = url.replace("http://", "https://");
      }
      url += "&pagination=true";
      this.$emit("nextPage", url);
    },
    resetCheckbox() {
      const checkboxes = document.querySelectorAll(".checkBoxesBatch");
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
    },
    resetCheckboxFalse() {
      setTimeout(() => {
        const checkboxes = document.querySelectorAll(".checkBoxesBatch");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      }, 2000);
    },
    changePassword(item) {
      router.push({
        path: `/${this.module}/changePassword/${item.encrypted_id}`,
      });
    },
    selectItem(item, redirect = false) {
      this.selectedItem = item;
      if (redirect == true) {
        if (this.module == "courses") {
          router.push({
            path: `/courseLevels/${this.selectedItem.encrypted_id}`,
          });
        } else if (this.module == "courseLevels") {
          router.push({
            path: `/courseMaterial/${this.selectedItem.encrypted_id}`,
          });
        } else if (this.module == "submitAssignments") {
          router.push({
            path: `/submissions/${this.selectedItem.encrypted_id}`,
          });
        } else if (this.module == "markAssigment") {
          router.push({
            path: `/markAssigment/${this.selectedItem.encrypted_id}`,
          });
        } else if (this.module == "feedback") {
          router.push({ path: `/feedback/${this.selectedItem.id}` });
        } else if (this.module == "batch") {
          router.push({
            path: `/batch/participent/${this.selectedItem.encrypted_id}`,
          });
        } else if (this.module == "reports") {
          router.push({
            path: `/reports/participants/${this.selectedItem.encrypted_id}`,
          });
        } else if (this.module == "participants") {
          router.push({
            path: `/participants/view/${this.selectedItem.encrypted_id}`,
          });
        } else if (this.module == "support") {
          router.push({
            path: `/support/view/${this.selectedItem.encrypted_id}`,
          });
        }
      }
    },
    toggleSelection(item) {
      const index = this.selectedStudents.findIndex(
        (selectedItem) => selectedItem.id === item.id
      );
      if (index !== -1) {
        this.selectedStudents.splice(index, 1);
      } else {
        this.selectedStudents.push(item);
      }
    },
    createGroup() {
      if (this.selectedStudents.length === 0) {
        this.$toast.show("Participants are not enough to create group", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        this.$emit("groupStudents", this.selectedStudents);
        this.selectedStudents = [];
        const checkboxes = document.querySelectorAll(".groupcheckboxes");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      }
    },
    // movePages(amount) {
    //   this.currentPage = amount;
    // },
    nextPage() {
      if (this.currentPage * this.pageSize < this.items.length)
        this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    openItem(item) {
      if (item.parent_id) {
        router.push({
          path: `/${this.module}/edit/${item.id}/${item.parent_id}`,
        });
      } else {
        if (this.module == "users") {
          router.push({
            path: `/${this.module}/edit/${item.encrypted_id}`,
            query: { type: this.type },
          });
        } else if (this.module == "assignment") {
          // router.push({ path: `/${this.module}/edit/${item.id}` });
          router.push({
            path: `/${this.module}/edit/${item.encrypted_id}`,
            query: { type: item.type },
          });
        } else {
          router.push({ path: `/${this.module}/edit/${item.encrypted_id}` });
        }
      }
    },
    blockUser() {
      this.loading = true;
      const id = toRaw(this.selectedItem.id);
      const data = {
        status: 2,
        block_reason: this.blockReason,
      };
      this.$store
        .dispatch(`${this.module}/updateUserStatus`, { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$emit("deleted");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    approveUser() {
      this.loading = true;
      const id = toRaw(this.selectedItem.id);
      const data = {
        status: 1,
      };
      this.$store
        .dispatch(`${this.module}/updateUserStatus`, { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly update", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$emit("deleted");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    editMaterial(item) {
      router.push(`/edit/batch/material/${item.encrypted_id}`);
    },

    confirmEndBatch() {
      this.$emit("endBatch", this.selectedItem.id);
    },

    checkboxChanged(id) {
      this.resetCheckboxFalse();
      this.$emit("checkboxUpdated", id);
    },
    handleDelete() {
      this.$emit("deleted");
    },
    handleStatus(status, id) {
      this.$emit("handle-status", status, id);
    },
    openEmail(item) {
      const email = item.email;
      const subject = "Support Request";
      const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(
        subject
      )}`;
      window.open(gmailUrl, "_blank");
    },
  },
};
</script>
<style scoped>
.level-btn {
  border-radius: 6px !important;
  height: 29px;
  min-width: 110px;
  font-size: 16px !important;
  color: #0074ce;
  border: 1px solid #0074ce !important;
  line-height: 15px;
  margin-right: 10px;
}

.editbatchend {
  margin-left: 5px;
}

.page-link.active {
  background-color: var(--bs-pagination-color) !important;
  color: #fff !important;
}
</style>
