<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-10 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Batch
              </h5>
            </div>
            <form ref="submitForm">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Course <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    v-model="batch.course_id"
                    @change="courseSelected"
                    required
                  >
                    <option value="">Select Course</option>
                    <option
                      v-for="(course, index) in courses"
                      :value="course.encrypted_id"
                      :key="index"
                    >
                      {{ course.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Title <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="custom-form-control"
                    v-model="batch.title"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color"
                    >Start Date <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.start_date"
                    required
                    :min="today"
                    @change="getEndDate"
                  />
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color"
                    >End Date <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.end_date"
                    required
                    :min="today"
                    readonly
                    disabled
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Vacation Start Date</label>
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.vacation_start_date"
                    :min="today"
                  />
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Vacation End Date</label>
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.vacation_end_date"
                    :min="today"
                  />
                </div>
              </div>
              <div class="form-group" v-if="role != null">
                <div class="col-md-12 mt-3">
                  <div class="row justify-content-between">
                    <label class="primary-text-color w-auto"
                      >Facilitator <span class="text-danger">*</span></label
                    >
                    <label
                      class="text-underline primary-text-color w-auto cursor-pointer"
                      @click="teacherDisabled = false"
                      >Edit</label
                    >
                  </div>
                  <select
                    class="custom-form-control"
                    v-model="batch.teacher_id"
                    required
                    :disabled="teacherDisabled"
                  >
                    <option value="">Select Facilitator</option>
                    <option v-for="teacher in teachers" :value="teacher.id">
                      {{ teacher.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="p-4 mt-3"
                style="
                  border-radius: 16px;
                  background-color: rgba(0, 116, 206, 0.2);
                "
              >
                <h5>Search Participants</h5>
                <div class="form-group row">
                  <div class="col-md-6 mt-3">
                    <label class="primary-text-color">District</label>
                    <select
                      class="custom-form-control"
                      v-model="district"
                      @change="fetchStudents"
                    >
                      <option value="">Select District</option>
                      <option
                        v-for="district in districts"
                        :value="district.id"
                        :key="district.id"
                      >
                        {{ district.name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-6 mt-3">
                    <label class="primary-text-color">Designation</label>
                    <select
                      class="custom-form-control"
                      v-model="designation"
                      @change="fetchStudents"
                    >
                      <option value="">Select Designation</option>
                      <option
                        v-for="designation in designations"
                        :value="designation.name"
                      >
                        {{ designation.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6 mt-3">
                    <label class="primary-text-color">Department</label>
                    <select
                      class="custom-form-control"
                      v-model="department_id"
                      @change="fetchStudents"
                    >
                      <option value="">Select Department</option>
                      <option
                        v-for="department in departments"
                        :value="department.id"
                      >
                        {{ department.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label class="primary-text-color">Qualification</label>
                    <select
                      class="custom-form-control"
                      v-model="qualification"
                      @change="fetchStudents"
                    >
                      <option value="">Select Qualification</option>
                      <option
                        v-for="qualification in qualifications"
                        :value="qualification.name"
                      >
                        {{ qualification.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mt-3" v-if="students.data.length > 0">
                  <p>
                    Participants Found: {{ students.data.length }} &nbsp;
                    &nbsp;<a
                      href="javascript:void(0);"
                      class="primary-text-color text-underline"
                      data-bs-toggle="modal"
                      data-bs-target="#participantModal"
                      >View List</a
                    >
                  </p>
                </div>
                <div class="mt-3" v-if="allStudents.data.length > 0">
                  <p>
                    Total Participants: {{ allStudents.data.length }} &nbsp;
                    &nbsp;<a
                      href="javascript:void(0);"
                      class="primary-text-color text-underline"
                      data-bs-toggle="modal"
                      data-bs-target="#totalParticipantModal"
                      >View List</a
                    >
                  </p>
                </div>
              </div>
              <div v-if="course && course.levels" class="row mt-3">
                <h4>Schedule your course material.</h4>
                <div id="courseAccordion" class="accordion">
                  <draggable
                    v-model="course.levels"
                    tag="div"
                    :animation="200"
                    :chosen-class="'chosen-level'"
                    :ghost-class="'ghost-level'"
                    @end="updateSorting(course.levels)"
                  >
                    <template #item="{ element: level, index: tableIndex }">
                      <div :key="tableIndex" class="accordion-item">
                        <h2
                          class="accordion-header"
                          :id="'heading' + tableIndex"
                        >
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#collapse' + tableIndex"
                            :aria-expanded="level.isOpen ? 'true' : 'false'"
                            :class="{ collapsed: !level.isOpen }"
                            :aria-controls="'collapse' + tableIndex"
                            :key="level.isOpen"
                          >
                            <h5 class="d-flex align-items-center">
                              <span class="text-muted me-2">{{
                                level.sorting
                              }}</span>
                              <span class="divider me-2"></span>
                              <span>{{ level.name }}</span>
                            </h5>
                          </button>
                        </h2>
                        <div
                          :id="'collapse' + tableIndex"
                          class="accordion-collapse collapse"
                          :class="level.isOpen ? 'show' : ''"
                          :aria-labelledby="'heading' + tableIndex"
                          data-bs-parent="#courseAccordion"
                          :key="level.isOpen"
                        >
                          <div class="accordion-body">
                            <div class="form-group">
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  v-model="level.mode"
                                  :id="'auto' + level.id"
                                  value="auto"
                                  :name="'modeName-' + level.name"
                                  required
                                />
                                <label
                                  class="form-check-label"
                                  :for="'auto' + level.id"
                                  >Auto</label
                                >
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  v-model="level.mode"
                                  :id="'manual' + level.id"
                                  value="manual"
                                  :name="'modeName-' + level.name"
                                  required
                                />
                                <label
                                  class="form-check-label"
                                  :for="'manual' + level.id"
                                  >Manual</label
                                >
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  v-model="level.mode"
                                  :id="'open' + level.id"
                                  value="open"
                                  :name="'modeName-' + level.name"
                                  required
                                />
                                <label
                                  class="form-check-label"
                                  :for="'open' + level.id"
                                  >Open</label
                                >
                              </div>
                            </div>

                            <table
                              class="table table-striped table-hover table-responsive"
                            >
                              <thead>
                                <tr>
                                  <th style="width: 150px">Material Number</th>
                                  <th style="width: 150px">Material Type</th>
                                  <th>Material Title</th>
                                  <th v-if="level.mode === 'manual'">
                                    Select Date
                                  </th>
                                </tr>
                              </thead>
                              <draggable
                                tag="tbody"
                                v-model="level.material"
                                :animation="200"
                                :chosen-class="'chosen-row'"
                                :ghost-class="'ghost-row'"
                                @end="updateSorting(level.material)"
                              >
                                <template #item="{ element, index }">
                                  <tr :key="element.id">
                                    <td>
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        :id="`sortingValue-${tableIndex}-${index}`"
                                        v-model="element.sorting"
                                        style="width: 50px"
                                        disabled
                                      />
                                    </td>
                                    <td>{{ element.type }}</td>
                                    <td v-if="element.type === 'Video'">
                                      {{ element.material.title }}
                                    </td>
                                    <td v-if="element.type === 'Quiz'">
                                      {{ element.material.name }}
                                    </td>
                                    <td v-if="element.type === 'Assignment'">
                                      {{ element.material.description }}
                                    </td>
                                    <td v-if="element.type === 'Document'">
                                      {{ element.material.title }}
                                    </td>
                                    <td v-if="level.mode === 'manual'">
                                      <input
                                        type="date"
                                        v-model="element.date"
                                        class="form-control form-control-sm"
                                        :min="today"
                                        required
                                      />
                                    </td>
                                  </tr>
                                </template>
                              </draggable>
                            </table>
                          </div>
                        </div>
                      </div>
                    </template>
                  </draggable>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    @click="handleSubmit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/batch"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Participant List Modal -->
    <div
      class="modal fade"
      id="participantModal"
      tabindex="-1"
      aria-labelledby="participantModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-5 mt-5">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Participant List
              </h5>
            </div>
            <data-table
              :columns="columns"
              :items="students"
              :isEdit="isEdit"
              :isDelete="isDelete"
              :isChecked="isChecked"
              @checkboxUpdated="handleCheckboxUpdated"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
    <!--Total Participant List Modal -->
    <div
      class="modal fade"
      id="totalParticipantModal"
      tabindex="-1"
      aria-labelledby="totalParticipantModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-5 mt-5">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Participant List
              </h5>
            </div>
            <data-table
              :columns="columns"
              :items="allStudents"
              :isEdit="isEdit"
              :isDelete="isDelete"
              :isTrashed="isTrashed"
              @checkboxUpdated="handleCheckboxUpdated"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import dataTable from "@/components/dataTable.vue";
import draggable from "vuedraggable";
import isNumber from "@/helpers/helpers";
import district from "@/store/modules/district";

export default {
  mixins: [isNumber],
  components: {
    dataTable,
    draggable,
  },
  data() {
    return {
      loading: false,
      batch: {
        title: null,
        course_id: "",
        teacher_id: "",
        start_date: null,
        end_date: null,
        vacation_start_date: null,
        vacation_end_date: null,
        student_ids: [],
      },
      id: "",
      formSubmitted: false,
      courses: [],
      course: [],
      teachers: [],
      departments: [],
      districts: [],
      department_id: "",
      students: {
        data: [],
      },
      allStudents: {
        data: [],
      },
      qualification: "",
      designation: "",
      district: "",
      columns: [
        {
          text: "Participant Name",
          value: "name",
        },
        {
          text: "District",
          value: "district",
          secondValue: "name",
        },
        {
          text: "Designation",
          value: "designation",
        },
        {
          text: "Department",
          value: "department",
          secondValue: "name",
        },
        {
          text: "Qualification",
          value: "qualification",
        },
      ],
      isEdit: false,
      isDelete: false,
      isChecked: true,
      isTrashed: true,
      role: localStorage.getItem("role"),
      qualifications: [],
      designations: [],
      today: null,
      teacherDisabled: true,
    };
  },
  mounted() {
    this.fetchCourses();
    this.fetchTeachers();
    this.fetchDepartments();
    this.fetchQualifications();
    this.fetchDesignations();
    this.fetchDistricts();
    this.today = new Date().toISOString().split("T")[0];
  },
  methods: {
    fetchStudents() {
      this.loading = true;
      const department = this.department_id;
      const qualification = this.qualification;
      const designation = this.designation;
      const district = this.district;
      this.$store
        .dispatch("participants/filterStudents", {
          department,
          qualification,
          designation,
          district,
        })
        .then((response) => {
          this.students.data = response.data.filter((student) => {
            return !this.allStudents.data.some(
              (existingStudent) => existingStudent.id === student.id
            );
          });
          this.allStudents.data.push(...this.students.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchCourses() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("courses/getAll", { search, pagination, facilitator_id })
        .then((response) => {
          this.courses = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchTeachers() {
      this.loading = true;
      const type = "Teacher";
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("facilitators/getAll", { type, search, pagination })
        .then((response) => {
          this.teachers = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDepartments() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("department/getAll", { search, pagination })
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchQualifications() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("qualification/getAll", { search, pagination })
        .then((response) => {
          this.qualifications = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDesignations() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("designation/getAll", { search, pagination })
        .then((response) => {
          this.designations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDistricts() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("district/getAll", { search, pagination })
        .then((response) => {
          this.districts = response.data;
        })
        .catch((e) => {
          console.log("district:", e);
        });
    },
    handleCheckboxUpdated(item) {
      const index = this.allStudents.data.findIndex(
        (student) => student.id === item.id
      );
      if (index !== -1) {
        this.allStudents.data.splice(index, 1);
      } else {
        this.allStudents.data.push(item);
      }
    },
    courseSelected() {
      this.loading = true;
      this.formSubmitted = true;
      const id = this.batch.course_id;
      this.$store
        .dispatch("courses/getSingle", { id })
        .then((response) => {
          this.course = response.data;
          this.course.levels.forEach((item, index) => {
            item.sorting = index + 1;
            item.isOpen = false;
          });
          this.batch.teacher_id = this.course.facilitator.id;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    handleSubmit(event) {
      event.preventDefault();
      this.openAllAccordions();
      const form = this.$refs.submitForm;
      if (form.checkValidity()) {
        this.submit();
      } else {
        const firstInvalidField = form.querySelector(":invalid");
        if (firstInvalidField) {
          firstInvalidField.focus();
          firstInvalidField.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }
    },
    submit() {
      let notHasMaterial = this.course.levels.some(
        (level) => Array.isArray(level.material) && level.material.length === 0
      );
      if (notHasMaterial) {
        this.$toast.show("One or more level has empty material.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        this.allStudents.data.forEach((item) => {
          this.batch.student_ids.push(item.id);
        });
        if (this.batch.student_ids.length <= 0) {
          this.$toast.show("Please add some participants", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
        } else {
          this.loading = true;
          const data = toRaw(this.batch);
          data.courseDetails = this.course;
          data.teacher_id =
            this.role == "teacher"
              ? localStorage.getItem("uid")
              : this.batch.teacher_id;
          this.$store
            .dispatch("batch/store", { data })
            .then((response) => {
              this.loading = false;
              if (response.success == true) {
                this.$toast.show("Successfuly added.", {
                  theme: "outline",
                  position: "top",
                  type: "success",
                  duration: 5000,
                });
                Object.keys(this.batch).forEach(
                  (key) => (this.batch[key] = null)
                );
                this.batch.course_id = "";
                this.batch.teacher_id = "";
                this.department_id = "";
                this.qualification = "";
                this.designation = "";
                this.students.data = [];
                this.allStudents.data = [];
                this.formSubmitted = true;
                this.course = [];
              } else {
                this.errors = response.error;
                console.log(response.error);
              }
            })
            .catch((e) => {
              console.log(e.error);
              this.loading = false;
            });
        }
      }
    },
    clearAll() {
      Object.keys(this.batch).forEach((key) => (this.batch[key] = null));
      this.batch.course_id = "";
      this.batch.teacher_id = "";
      this.department_id = "";
      this.qualification = "";
      this.designation = "";
      this.students.data = [];
      this.allStudents.data = [];
    },
    getEndDate() {
      let date = new Date(this.batch.start_date);
      date.setMonth(date.getMonth() + this.course.duration);
      let day = String(date.getDate()).padStart(2, "0");
      let month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      let year = date.getFullYear();
      let formatedDate = `${year}-${month}-${day}`;
      this.batch.end_date = formatedDate;
    },
    updateSorting(materials) {
      materials.forEach((item, index) => {
        item.sorting = index + 1;
      });
    },
    openAllAccordions() {
      if (this.course && this.course.levels) {
        this.course.levels.forEach((level, index) => {
          level.isOpen = false;
        });
        setTimeout(() => {
          this.course.levels.forEach((level, index) => {
            level.isOpen = true;
          });
        }, 1000);
      }
    },
  },
};
</script>
<style scoped>
.modal-content {
  min-height: 800px;
  max-height: 800px;
}
.chosen-row {
  background-color: #f0f8ff !important;
  opacity: 0.9;
}

.ghost-row {
  background-color: #d3d3d3;
  opacity: 0.5;
}
.divider {
  width: 1px;
  height: 1.5rem;
  background-color: #ccc;
  display: inline-block;
}
</style>
