<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Video
              </h5>
            </div>
            <form @submit.prevent="submit" ref="addRole">
              <div class="form-group">
                <label class="primary-text-color"
                  >Title <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="howTo.title"
                  class="custom-form-control mb-3"
                  required
                />
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Upload Thumbnail <span class="text-danger">*</span></label
                  >
                  <div class="position-relative">
                    <input
                      type="file"
                      ref="thumbnail"
                      class="position-relative"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="selectThumbnail"
                      style="opacity: 0; z-index: 100"
                      required
                    />
                    <button
                      type="button"
                      class="btn btn-primary primary-bg-color position-absolute"
                      style="left: 0"
                    >
                      Browse...
                    </button>
                  </div>
                </div>
                <div class="image-group">
                  <div class="col-md-4 mt-3">
                    <img
                      v-if="thumbnailPreview"
                      :src="thumbnailPreview"
                      class="img-thumbnail"
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div v-if="ImageUploadingPercentage > 0">
                  <progress :value="ImageUploadingPercentage" max="100">
                    {{ ImageUploadingPercentage }}%
                  </progress>
                  <p>{{ ImageUploadingPercentage }}%</p>
                </div>
              </div>

              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Upload Video <span class="text-danger">*</span></label
                  >
                  <div class="position-relative">
                    <input
                      type="file"
                      ref="video"
                      class="position-relative"
                      accept="video/*"
                      @change="selectVideo($event)"
                      style="opacity: 0; z-index: 100"
                      required
                    />
                    <button
                      type="button"
                      class="btn btn-primary primary-bg-color position-absolute"
                      style="left: 0"
                    >
                      Browse...
                    </button>
                  </div>
                </div>
                <div class="col-md-12" v-if="videoPreview">
                  <video
                    class="mt-3 w-100"
                    id="video-preview"
                    controls
                    :src="videoPreview"
                  ></video>
                </div>
              </div>
              <div class="row mt-3">
                <div v-if="fileUploadingPercentage > 0">
                  <progress :value="fileUploadingPercentage" max="100">
                    {{ fileUploadingPercentage }}%
                  </progress>
                  <p>{{ fileUploadingPercentage }}%</p>
                </div>
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                    :disabled="fileUploadingPercentage > 0"
                  >
                   {{ (fileUploadingPercentage > 0 || ImageUploadingPercentage > 0 ) ? "Pleae wait , File Uploading" : "Save" }}
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/how-to"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import axios from "@/helpers/axios";

export default {
  data() {
    return {
      thumbnailPreview: "",
      videoPreview: null,
      loading: false,
      uploadProgress: null,
      howTo: {
        title: "",
        video: null,
        image:null,
      },
      uploadProgress: 0,
      fileUploadingPercentage: 0,
      ImageUploadingPercentage: 0,
    };
  },
  methods: {
    selectVideo(event) {
      const file = event.target.files[0];
      this.videoPreview = URL.createObjectURL(file);
      this.fileUploadingPercentage = 0.1;
      const chunkSize = 5 * 1024 * 1024;
      this.uploadFileInChunks(file, chunkSize);
    },
    async uploadFileInChunks(file, chunkSize) {
      const totalChunks = Math.ceil(file.size / chunkSize);
      const timestamp = Math.floor(Date.now() / 1000);
      const fileExtension = file.name.split(".").pop();
      const folder = "HowToVideos";
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append("chunk", chunk);
        formData.append("index", i + 1);
        formData.append("totalChunks", totalChunks);
        formData.append("timestamp", timestamp);
        formData.append("fileExtension", fileExtension);
        formData.append("folder", folder);
        const response = await axios.post("/upload-video", formData);
        if (response.data.success) {
          this.fileUploadingPercentage = (
            ((i + 1) / totalChunks) *
            100
          ).toFixed(2);
          this.howTo.video = response.data.file;
          if (response.data.videoStatus == true) {
            this.fileUploadingPercentage = 0;
          }
        } else {
          this.fileUploadingPercentage = 0;
          this.$refs.video.value = "";
          this.$toast.show("Not Save", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
          return;
        }
      }
    },
    selectThumbnail(event) {
      const file = event.target.files[0];
      this.thumbnailPreview = URL.createObjectURL(file);
      this.ImageUploadingPercentage = 0.1;
      const chunkSize = 5 * 1024 * 1024;
      this.uploadFileInChunksImage(file, chunkSize);
    },
    async uploadFileInChunksImage(file, chunkSize) {
      const totalChunks = Math.ceil(file.size / chunkSize);
      const timestamp = Math.floor(Date.now() / 1000);
      const fileExtension = file.name.split(".").pop();
      const folder = "HowToVideos";
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append("chunk", chunk);
        formData.append("index", i + 1);
        formData.append("totalChunks", totalChunks);
        formData.append("timestamp", timestamp);
        formData.append("fileExtension", fileExtension);
        formData.append("folder", folder);
        const response = await axios.post("/upload-video", formData);
        if (response.data.success) {
          this.ImageUploadingPercentage = (
            ((i + 1) / totalChunks) *
            100
          ).toFixed(2);
          this.howTo.image = response.data.file;
          if (response.data.videoStatus == true) {
            this.ImageUploadingPercentage = 0;
          }
        } else {
          this.ImageUploadingPercentage = 0;
          this.$refs.thumbnail.value = "";
          this.$toast.show("Not Save", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
          return;
        }
      }
    },
    onUploadProgress(progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.howTo);
      this.$store
        .dispatch("howTo/store", {
          data,
          onUploadProgress: this.onUploadProgress,
        })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            (this.howTo.title = ""),
              (this.videoPreview = ""),
              (this.thumbnailPreview = "");
            this.$refs.video.value = "";
            this.$refs.thumbnail.value = "";
            this.uploadProgress = 0;
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    clearAll() {
      (this.howTo.title = ""),
        (this.videoPreview = ""),
        (this.thumbnailPreview = "");
      this.$refs.video.value = "";
      this.$refs.thumbnail.value = "";
    },
  },
};
</script>

<style>
.progress {
  height: 25px;
}

.progress-bar {
  transition: width 0.3s ease-in-out;
}
</style>
